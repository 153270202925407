<template>
    <UHeader title="NomadRx" to="/home">
        <template #logo>
            <p class="tracking-tight">NomadRx</p>
        </template>
        <template #right>
            <div class="hidden lg:flex items-center gap-1.5">
                <!-- <UColorModeButton /> -->
                <UDropdown :items="accountItems">
                    <UButton color="gray" variant="ghost" square icon="i-heroicons-user-solid" />
                </UDropdown>
            </div>
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <!-- <UColorModeButton block label="Color mode" variant="soft" size="xl" /> -->
                <UButton label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle" @click="signOutUser" block
                    variant="soft" size="xl" />
            </div>
        </template>
    </UHeader>
</template>
<script setup lang="ts">

const { signOutUser } = useFirebaseAuth();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const accountItems = [
    [{
        label: 'Settings',
        icon: 'i-heroicons-cog-8-tooth',
        to: "/home/settings"
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]

const links = [{
    label: 'Home',
    to: '/home',
    exact: true,
}, {
    label: 'Claims',
    to: '/home/claims'
}, {
    label: 'Billing',
    to: '/home/billing'
}, {
    label: 'Settings',
    to: '/home/settings'
}]
</script>